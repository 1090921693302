import { EnvironmentEnum, EnvironmentManager } from '@shared/model/environment';

export class ENV {
    public static SERVICE_TARGET = window['SERVICE_TARGET'];
    public static ENVIRONMENT = window['ENVIRONMENT'];
    public static SSO_CLIENT_ID = window['SSO_CLIENT_ID'];
    public static SSO_LOGIN_URL = window['SSO_LOGIN_URL'];
    public static APPLICATION_BUILD = window['APPLICATION_BUILD'];

    public static getEnvironment(): EnvironmentEnum {
        return EnvironmentManager.forString(ENV.ENVIRONMENT);
    }

    public static isProd(): boolean {
        return this.getEnvironment() === EnvironmentEnum.PROD;
    }

    public static isTraining(): boolean {
        return this.getEnvironment() === EnvironmentEnum.TRAINING;
    }

}
